<template>
  <div class="data-box">
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">人员姓名</div>
          <div class="span-value">{{personInfo.materialPerson ? personInfo.materialPerson : "无"}}</div>
        </div>
        <div class="body-div-item">
          <div class="span-key">领用周期</div>
          <div class="span-value">{{personInfo.materialCycle ? personInfo.materialCycle : "无"}}</div>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-note">
          请注意每次物资发放的数量
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt style="width: 25%;">物品名称</dt>
              <dt style="width: 25%;">单次限领</dt>
              <dt style="width: 25%;">本次发放</dt>
              <dt style="width: 25%;" @click="changeAllItem">
                <i :class="[isCheckedAll ? 'icon-border-checked-yes item-check' : 'icon-border-checked-no', 'iconfont']"></i>
              </dt>
            </tr>
            <tr v-for="(item,index) in materialList" :key="index">
              <dd style="width: 25%;color: #3476FF;" @click="toMaterialDetails(item)"><span style="color: #FA5555;" v-show="item.num3">*</span>{{ item.str }}</dd>
              <dd style="width: 25%;">{{item.num2}}</dd>
              <dd style="width: 25%;"><input class="van-cell" type="number" v-model="item.getNum" placeholder="请输入数量" maxlength="8" @input="inputHandler(item,index)"/></dd>
              <dd style="width: 25%;" @click="changeItem(index)">
                <i :class="[item.isChecked ? 'icon-border-checked-yes item-check' : 'icon-border-checked-no', 'iconfont']"></i>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">发放人</div>
          <div class="span-input">
            <van-field
                v-model="personInfo.pictureName"
                placeholder="请输入发放人"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">联系电话</div>
          <div class="span-input">
            <van-field
                v-model="personInfo.notePhone"
                placeholder="请输入联系电话"
                maxlength="11"
                type="digit"
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button round block type="info" @click="confirmSend">确认发放</van-button>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
import {
  wechatWhetherBindPhone,
  showActivityMaterial,
  grantMaterial
} from "@/api/index";

export default {
  name: "parMaterial_details",
  data() {
    return {
      showPersonSheet: false,
      showDetailsPopup: false,
      materialList: [],
      isCheckedAll: false,
      personInfo: {
        activityId: 0,
        materialPerson: "",
        materialCycle: "",
        pictureName: "",
        notePhone: ""
      }
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let id = that.$route.query.id;
      that.$tool.getActObjInfo(id, (data) => {
        that.personInfo.activityId = data.id;
        that.personInfo.materialPerson = data.chargeName + " " + data.chargePhone;
        that.personInfo.materialCycle = data.actStartDate + " ~ " + data.actEndDate;
      });
      that.getMaterialInfo();
      /*let paramsStr = location.search.split("&");
      let code = paramsStr[0].split("=")[1];
      let state = paramsStr[1].split("=")[1];
      let params = {code: code}
      wechatWhetherBindPhone(params).then(res => {
        that.$tool.resValidation(res, () => {
          console.log(res);
        })
      })*/
    },
    getMaterialInfo(){
      let that = this;
      let params = {
        ayId : that.$store.getters.getOPDetails.id
      };
      showActivityMaterial(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialList = res.data.materialList;
          that.materialList.forEach(item => {
            item.getNum = null;
            item.isChecked = false;
            //item.num4 = Math.abs(item.num4);
          })
        })
      })
    },
    //本次发放输入验证
    inputHandler(item,index){
      let that = this;
      item.getNum = Number(item.getNum) > item.num2 ? item.num2 : Number(item.getNum);
      this.$set(that.materialList, index, item);
    },
    changeAllItem(){
      let that = this;
      that.isCheckedAll = !that.isCheckedAll;
      that.materialList.forEach(item => {
        item.isChecked = that.isCheckedAll;
      })
    },
    changeItem(index){
      let that = this;
      that.materialList[index].isChecked = !that.materialList[index].isChecked;
      this.$set(that.materialList, index, that.materialList[index]);
    },
    toMaterialDetails(item){
      let that = this;
      that.$router.push({
        name: 'orgCheck_material_details',
        query: {
          id: item.id,
          ayId: that.personInfo.activityId
        }
      });
    },
    confirmSend(){
      let that = this;
      let array = [];
      let isEmpty = false;
      if(that.personInfo.pictureName === ""){
        that.$toast('请输入发放人姓名！');
        return;
      }
      if(that.personInfo.notePhone === ""){
        that.$toast('请输入发放人联系电话！');
        return;
      }
      that.materialList.forEach(item => {
        if(item.isChecked){
          if(!item.getNum){
            isEmpty = true;
          }
          let obj = {
            "activityId": that.personInfo.activityId,
            "notePhone": that.personInfo.notePhone,
            "num": -item.getNum,
            "pictureName": that.personInfo.pictureName,
            "type": 1
          };
          array.push(obj);
        }
      })
      if(array.length === 0){
        that.$toast('请勾选领取物资！');
        return;
      }
      if(isEmpty){
        that.$toast('已勾选的物资发放数量必填！');
        return;
      }
      grantMaterial(array).then(res => {
        that.$tool.resValidation(res,() => {
          that.$router.push({
            path: `/par_success`,
            query: {
              type: 1
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.item-check{
  color: #3476FF;
}
.van-cell{
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 10px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  border-bottom-right-radius: 10px;
  border: 0px;
}
.popup-head{
  width: 100%;
  margin: 20px 0px;
  text-align: center;
  color: #3E4576;
  font-size: 20px;
  font-weight: bold;
}
.popup-body{
  width: 100%;
  .popup-body-item{
    width: 90%;
    margin: 14px auto;
    display: flex;
    flex-direction: row;
    .item-key{
      width: 24%;
      color: #8B8FAD;
      font-size: 16px;
    }
    .item-value{
      width: 76%;
      color: #3E4576;
      font-size: 16px;
    }
    .item-image{
      width: 76%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .image{
        width: 30%;
        margin-right: 3%;
      }
    }
  }
}
</style>